import { Component } from '@angular/core';
import { ButtonslideComponent } from './buttonslide/buttonslide.component';
import { ViewChild } from '@angular/core'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NumberDiagComponent } from './number-diag/number-diag.component';
import { DrawTouchComponent } from './draw-touch/draw-touch.component';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'pnphelper';
  @ViewChild('life') life: ButtonslideComponent;
  @ViewChild('gold') gold: ButtonslideComponent;
  @ViewChild('actionpoints') actionpoints: ButtonslideComponent;
  @ViewChild('touchdraw') touchdraw: DrawTouchComponent;
  @ViewChild('reaction') reaction: MatButton;
 
  
  valueLoaded : boolean = false;
  storeTimer;
  elem;

  constructor(public dialog: MatDialog){}
  
  ngAfterViewInit(): void {   
    this.elem = document.documentElement;
    this.storeTimer = setInterval(() => {
      this.saveState();
    },1000)

    this.actionpoints.showStepM2 = false;
    this.actionpoints.showStepM3 = false;
    this.actionpoints.showStepP2 = false;
    this.actionpoints.showStepP3 = false;

    this.loadValues();
    
  }

  editLife() : void{
    const dialogRef = this.dialog.open(NumberDiagComponent, {
      width: '515px',
      data: this.life.maxValue
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.life.maxValue = result;
      var lifeVal = this.life.value;
      this.life.setValue(1);
      this.life.setValue(lifeVal);
    });
  }

  loadValues()
  {    
    if(isNaN(parseInt(localStorage.getItem('ActionPoints'))))
    {
      // nothing to load 
      this.valueLoaded = true;
      return;
    }

    this.life.maxValue = parseInt(localStorage.getItem('LifeMaxValue'));
    this.life.setValue(parseInt(localStorage.getItem('LifeValue')));
    this.gold.setValue(parseInt(localStorage.getItem('Gold')));
    this.actionpoints.setValue(parseInt(localStorage.getItem('ActionPoints')));

    this.valueLoaded = true;
  }

  saveState(){
    if(!this.valueLoaded)
      return;

    localStorage.setItem("LifeValue", this.life.value.toString());
    localStorage.setItem("LifeMaxValue", this.life.maxValue.toString());
    localStorage.setItem("Gold", this.gold.value.toString());
    localStorage.setItem("ActionPoints", this.actionpoints.value.toString());
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    if (this.elem.exitFullscreen) {
      this.elem.exitFullscreen();
    } else if (this.elem.mozCancelFullScreen) {
      /* Firefox */
      this.elem.mozCancelFullScreen();
    } else if (this.elem.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitExitFullscreen();
    } else if (this.elem.msExitFullscreen) {
      /* IE/Edge */
      this.elem.msExitFullscreen();
    }
  }

  reactionUsed(){

  }

}
