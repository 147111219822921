import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  templateUrl: './number-diag.component.html',
  styleUrls: ['./number-diag.component.css']
})
export class NumberDiagComponent implements OnInit {

  newMaxLife : number;

  constructor(
    public dialogRef: MatDialogRef<NumberDiagComponent>,
    @Inject(MAT_DIALOG_DATA) public intValue : number) { 
      this.newMaxLife = 0;
    }

    addDigit(digit : number) : void{
      this.newMaxLife = parseInt(this.newMaxLife.toString() + digit.toString());
    }

    del():void{
      this.newMaxLife = 0;
    }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
