
<div>
<div fxLayout="row" fxFlexFill>
    <div fxLayout="row">
        <button mat-raised-button color="warn" class="sliderButton" (click)="changeValue(stepM1)">{{stepM1}}</button>
        <button *ngIf="showStepM2" mat-raised-button color="warn" class="sliderButton" (click)="changeValue(stepM2)">{{stepM2}}</button>
        <button *ngIf="showStepM3" mat-raised-button color="warn" class="sliderButton" (click)="changeValue(stepM3)">{{stepM3}}</button>
    </div>

    <div fxLayout="column" style="width: 300px" fxLayoutAlign="center">
        <div fxLayout="row wrap" fxLayoutAlign="center center">
            <span class="sliderText">{{displayName}} {{value}}</span> <img *ngIf="ShowHearth" src="/assets/heart.png" alt="image" height="30px"> <img *ngIf="ShowGold" src="/assets/gold.png" alt="image" height="30px"><img *ngIf="ShowActions" src="/assets/actions.png" alt="image" height="30px">
        </div>

        <div *ngIf="ShowProgressbar" fxLayout="row" fxLayoutAlign="center start">
            <mat-progress-bar mode="determinate" value={{percent}}></mat-progress-bar>
        </div>
    </div>

    <div fxLayout="row"style="margin-left: 10px;"> 
        <button *ngIf="showStepP3" mat-raised-button color="accent" class="sliderButton" (click)="changeValue(stepP3)">+{{stepP3}}</button>
        <button *ngIf="showStepP2" mat-raised-button color="accent" class="sliderButton" (click)="changeValue(stepP2)">+{{stepP2}}</button>
        <button mat-raised-button color="accent" class="sliderButton" (click)="changeValue(stepP1)">+{{stepP1}}</button>
    </div>
</div>
</div>