import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-buttonslide',
  templateUrl: './buttonslide.component.html',
  styleUrls: ['./buttonslide.component.css']
})
export class ButtonslideComponent implements OnInit {

   @Input() maxValue : number = 100;
   @Input() minValue : number = 0;
   @Input() value : number = 50;

   @Input() stepP1 : number = 1;
   @Input() stepP2 : number = 5;
   @Input() stepP3 : number = 10;
   @Input() showStepP3 : boolean = true;
   @Input() showStepP2 : boolean = true;

   @Input() stepM1 : number = -1;
   @Input() stepM2 : number = -5;
   @Input() stepM3 : number = -10;
   @Input() showStepM3 : boolean = true;
   @Input() showStepM2 : boolean = true;

   @Input() ShowProgressbar : boolean = true;
   @Input() displayName : string;

   @Input() ShowHearth : boolean = false;
   @Input() ShowGold : boolean = false;
   @Input() ShowActions : boolean = false;
   @Input() ShowEditMaxLife : boolean = false;

   percent : number = 50;

  constructor() { }

  ngOnInit(): void {
    this.calculatePercent();
  }

  setValue(val) {
    if(isNaN(val))
        return;

    this.value = val;

    if(this.value < this.minValue)
        this.value = this.minValue;

    if(this.value > this.maxValue)
      this.value = this.maxValue;

    this.calculatePercent();
  }

  changeValue(_value : number) {
      if(isNaN(_value))
        return;

      this.value = +this.value + _value;

      if(this.value < this.minValue)
        this.value = this.minValue;

      if(this.value > this.maxValue)
        this.value = this.maxValue;

      this.calculatePercent();
  }

  calculatePercent() {
    this.percent = this.value / this.maxValue * 100;
  }

}
