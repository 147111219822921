<h1 mat-dialog-title>Leben ändern</h1>
<div mat-dialog-content>
  <p>altes Maximal-Leben: {{intValue}}</p>

  <p class="diagText">neues Maximal-Leben: {{newMaxLife}}</p>

  <div fxLayout="row" >
    <div fxLayout="column" >
        <button mat-raised-button color="primary" class="diagButton" (click)="addDigit(1)">1</button>
    </div>
    <div fxLayout="column" >
        <button mat-raised-button color="primary" class="diagButton" (click)="addDigit(2)">2</button>
      </div>
      <div fxLayout="column" >
        <button mat-raised-button color="primary" class="diagButton" (click)="addDigit(3)">3</button>
      </div>
    </div>
    <div fxLayout="row" style="margin-top: 10px;">
        <div fxLayout="column" >
            <button mat-raised-button color="primary" class="diagButton" (click)="addDigit(4)">4</button>
        </div>
        <div fxLayout="column" >
            <button mat-raised-button color="primary" class="diagButton" (click)="addDigit(5)">5</button>
          </div>
          <div fxLayout="column" >
            <button mat-raised-button color="primary" class="diagButton" (click)="addDigit(6)">6</button>
          </div>
        </div>

        <div fxLayout="row"  style="margin-top: 10px;">
            <div fxLayout="column" >
                <button mat-raised-button color="primary" class="diagButton" (click)="addDigit(7)">7</button>
            </div>
            <div fxLayout="column" >
                <button mat-raised-button color="primary" class="diagButton" (click)="addDigit(8)">8</button>
              </div>
              <div fxLayout="column" >
                <button mat-raised-button color="primary" class="diagButton" (click)="addDigit(9)">9</button>
              </div>
            </div>
            <div fxLayout="row"  style="margin-top: 10px;">
                <div fxLayout="column" >
                    <button mat-raised-button color="warn" class="diagButton" (click)="del()">DEL</button>
                </div>
                <div fxLayout="column" >
                    <button mat-raised-button color="primary" class="diagButton" (click)="addDigit(0)">0</button>
                  </div>
                </div>


</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="intValue">Abbrechen</button>
  <button mat-button cdkFocusInitial [mat-dialog-close]="newMaxLife"  style="width: 100px;height: 70px;">OK</button>
</div>
