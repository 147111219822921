<div fxLayout="column" style="cursor: none;">
  <div fxLayout="row" fxLayoutAlign="center center">
    <h1>Wolli's P'n'P Helper (for Alex 😘)</h1> &nbsp;&nbsp;&nbsp; <h2 (click)="openFullscreen()"> open </h2> &nbsp;&nbsp;&nbsp; <h2 (click)="closeFullscreen()"> close </h2>

  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <app-buttonslide #life value=20 maxValue=30 displayName="Leben:" ShowHearth=true></app-buttonslide>
  </div>
  
  <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 10px;">
    <app-buttonslide #gold value=30 displayName="Gold:" maxValue=500 ShowGold=true></app-buttonslide>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 10px;">
    <div fxLayout="row">
      <button mat-raised-button color="primary" class="standardButton" (click)="editLife()">Maximales Leben ändern</button>
      <button mat-raised-button color="warn" class="standardButton" (click)="this.touchdraw.clear()">Notizen löschen</button>
    </div>      
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 20px;">
    <h2>Kampf</h2>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 5px;">
    <app-buttonslide #actionpoints value=6 displayName="Action Points:" ShowActions=true maxValue=8 showStepP3=false showStepP2=false showStepM3=false showStepM2=false></app-buttonslide>
    <button #reaction mat-raised-button color="accent" class="standardButton" (click)="this.reaction.color = 'warn'" style="margin-left: 150px;">Reaction</button>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 10px;">
    <div fxLayout="row">
      <button mat-raised-button color="accent" class="standardButton" (click)="this.actionpoints.changeValue(6); this.reaction.color = 'accent'">Neue Runde (+6 AP)</button>
      <button mat-raised-button color="primary" class="standardButton" (click)="this.actionpoints.changeValue(-2)">Schlag (-2 AP)</button>
      <button mat-raised-button color="primary" class="standardButton" (click)="this.actionpoints.changeValue(-1)">Gehen/Klettern/Deckung (-1 AP)</button>
      <button mat-raised-button color="primary" class="standardButton" (click)="this.actionpoints.changeValue(-1)">Schnelligkeit +1 (-1 AP)</button>      
      <button mat-raised-button color="primary" class="standardButton" (click)="this.actionpoints.changeValue(-1)">Umrüsten (-1 AP)</button>      
      <button mat-raised-button color="primary" class="standardButton" (click)="this.actionpoints.changeValue(-3)">Heiltrank (-3 AP)</button>
      <button mat-raised-button color="primary" class="standardButton" (click)="this.actionpoints.changeValue(-2)">Bufftrank (-2 AP)</button>      
    </div>      
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 20px;">
    <h2>Notizen</h2>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 10px;">
    <app-draw-touch #touchdraw width=1700></app-draw-touch>
  </div>

</div>


<router-outlet></router-outlet>